@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/MaterialIcons-Regular.eot");

  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("fonts/MaterialIcons-Regular.woff") format("woff"),
    url("fonts/MaterialIcons-Regular.ttf") format("truetype");
}
/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ibm-plex-sans-v7-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("./fonts/ibm-plex-sans-v7-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/ibm-plex-sans-v7-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-regular.svg#IBMPlexSans")
      format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/ibm-plex-sans-v7-latin-700.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("./fonts/ibm-plex-sans-v7-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/ibm-plex-sans-v7-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/ibm-plex-sans-v7-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/ibm-plex-sans-v7-latin-700.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}

* {
  box-sizing: border-box;
}

html {
  background: var(--shade);
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 21px;

  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

a {
  color: inherit;
  cursor: pointer;
}

:root {
  --main: #7454d4;
  --error: #ff9042;
  --shade: #f3f3f3;
  --grey: #ccc;

  --data-color-10: #1daf79;
  --data-color-9: #2ecc93;
  --data-color-8: #2aafb7;
  --data-color-7: #44c7e6;
  --data-color-6: #1d92da;
  --data-color-5: #564dca;
  --data-color-4: #9181de;
  --data-color-3: #a14dca;
  --data-color-2: #a41fa5;
  --data-color-1: #e660bb;
  --data-color-0: #de307f;
}
